var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-table-actions',{attrs:{"title":_vm.title,"show-search":false}},[(_vm.allowAccessFor(['superadmin', 'admin', 'operations']))?_c('template',{slot:"actions"},[_c('el-dropdown',{staticClass:"ml-10"},[_c('el-button',{attrs:{"type":"plain"},on:{"click":function($event){_vm.showCalendar = true}}},[_vm._v(" Showing: "+_vm._s(_vm.dateSet)+" "),_c('i',{staticClass:"eden-icon-arrow-down"})])],1),_c('el-dropdown',{staticClass:"ml-10",on:{"command":_vm.menuChange}},[_c('el-button',{attrs:{"type":"plain"}},[_vm._v(" "+_vm._s(_vm.menuType.text)+" "),_c('i',{staticClass:"eden-icon-arrow-down"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.menuTypeOptions),function(type,ix){return _c('el-dropdown-item',{key:ix,attrs:{"command":type}},[_vm._v(_vm._s(type.text))])}),1)],1),_c('el-button',{staticClass:"ml-10",attrs:{"type":"primary"},on:{"click":_vm.addNewMenu}},[_vm._v("Add new Menu")])],1):_vm._e()],2),(_vm.loading)?_c('div',[_vm._v("Loading...")]):_c('div',[_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{attrs:{"min-width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"text-cursor",on:{"click":function($event){return _vm.viewMenu(scope.row.id)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(scope.row.from_date, "dddd do, m, y"))+" ")]),_c('i',{staticClass:"el-icon-right mx-4"}),_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(scope.row.to_date, "dddd do, m, y"))+" ")]),(
                _vm.getDateDifference({
                  sDate: scope.row.from_date,
                  fDate: new Date(),
                }) <= 7 && scope.row.is_published
              )?_c('el-tag',{attrs:{"type":_vm.setType('current')}},[_vm._v(" Current ")]):_vm._e()],1)]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Menu Window")])])],2),_c('el-table-column',{attrs:{"min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(scope.row.menu_items_service_type_id.length))])]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Number of Meals")])])],2),_c('el-table-column',{attrs:{"min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.getMenuType(scope.row.menu_type_id).name))])]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Menu Type")])])],2),_c('el-table-column',{attrs:{"min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":_vm.setType(scope.row.is_published ? 'published' : 'draft')}},[_vm._v(" "+_vm._s(scope.row.is_published ? "Published" : "Draft")+" ")])]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Status")])])],2),(
          _vm.allowAccessFor(['superadmin', 'admin', 'operations', 'kitchen'])
        )?_c('el-table-column',{attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"more",on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"eden-icon-edit","command":{
                  action: 'edit',
                  id: scope.row.id,
                  index: scope.$index,
                }}},[_vm._v("Edit menu")]),_c('el-dropdown-item',{attrs:{"icon":"el-icon-link","command":{
                  action: 'duplicate',
                  id: scope.row.id,
                  index: scope.$index,
                }}},[_vm._v("Duplicate menu")]),_c('el-dropdown-item',{attrs:{"icon":"eden-icon-eye","command":{
                  action: 'recommendations',
                  id: scope.row.id,
                  menu: scope.row,
                  index: scope.$index,
                }}},[_vm._v("View recommendations")]),_c('el-dropdown-item',{attrs:{"icon":"eden-icon-delete","command":{
                  action: 'delete',
                  id: scope.row.id,
                  index: scope.$index,
                }}},[_vm._v("Delete menu")])],1)],1)]}}],null,false,4065583309)}):_vm._e()],1),_c('menu-recommendations-view',{attrs:{"show":_vm.showMenuRecommendationsView,"menu":_vm.selectedMenu},on:{"update:show":function($event){_vm.showMenuRecommendationsView=$event}}}),_c('menu-recommendations-generate',{attrs:{"show":_vm.showMenuRecommendationsGenerate,"menu-id":_vm.menuId},on:{"update:show":function($event){_vm.showMenuRecommendationsGenerate=$event}}}),_c('eden-confirm-dialog',{attrs:{"title":`${_vm.formatText(_vm.selectionWindowAction)} meal selection window`,"button-text":`Yes, ${_vm.selectionWindowAction}`,"button-type":_vm.selectionWindowAction === 'open' ? 'success' : 'danger',"show":_vm.showSelectionWindow},on:{"update:show":function($event){_vm.showSelectionWindow=$event},"confirm":_vm.changeSelectionWindow}},[_c('p',[_vm._v(" Are you sure you want to "+_vm._s(_vm.selectionWindowAction)+" the meal selection window? ")])]),_c('eden-custom-calendar',{attrs:{"show":_vm.showCalendar},on:{"update:show":function($event){_vm.showCalendar=$event},"setdate":_vm.setdate}}),(_vm.showPagination)?_c('eden-pagination',{attrs:{"from":_vm.from,"to":_vm.to,"total":_vm.total,"current-page":_vm.page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }