<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
      <template
        slot="actions"
        v-if="allowAccessFor(['superadmin', 'admin', 'operations'])"
      >
        <!-- <el-button type="text">
          <span class="text-grey-primary mr-1">Open Meal Selection Window</span>
          <el-switch
            :active-value="!toggleSelectionWindow"
            :inactive-value="toggleSelectionWindow"
            :disabled="!allowAccessFor(['superadmin', 'admin', 'operations'])"
            @change="showSelectionWindow = true"
          >
          </el-switch
        ></el-button> -->
        <el-dropdown class="ml-10">
          <el-button type="plain" @click="showCalendar = true">
            Showing: {{ dateSet }} <i class="eden-icon-arrow-down"></i>
          </el-button>
        </el-dropdown>
        <el-dropdown @command="menuChange" class="ml-10">
          <el-button type="plain">
            {{ menuType.text }} <i class="eden-icon-arrow-down"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              :command="type"
              v-for="(type, ix) in menuTypeOptions"
              :key="ix"
              >{{ type.text }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <el-button type="primary" class="ml-10" @click="addNewMenu"
          >Add new Menu</el-button
        >
      </template>
    </eden-table-actions>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <el-table :data="pageData">
        <el-table-column min-width="350">
          <template slot="header">
            <span>Menu Window</span>
          </template>
          <template v-slot="scope">
            <div class="text-cursor" @click="viewMenu(scope.row.id)">
              <span>
                {{ formatDate(scope.row.from_date, "dddd do, m, y") }}
              </span>
              <i class="el-icon-right mx-4"></i>
              <span>
                {{ formatDate(scope.row.to_date, "dddd do, m, y") }}
              </span>
              <el-tag
                v-if="
                  getDateDifference({
                    sDate: scope.row.from_date,
                    fDate: new Date(),
                  }) <= 7 && scope.row.is_published
                "
                :type="setType('current')"
              >
                Current
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="200">
          <template slot="header">
            <span>Number of Meals</span>
          </template>
          <template v-slot="scope">
            <p>{{ scope.row.menu_items_service_type_id.length }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="200">
          <template slot="header">
            <span>Menu Type</span>
          </template>
          <template v-slot="scope">
            <p>{{ getMenuType(scope.row.menu_type_id).name }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="200">
          <template slot="header">
            <span>Status</span>
          </template>
          <template slot-scope="scope">
            <el-tag
              :type="setType(scope.row.is_published ? 'published' : 'draft')"
            >
              {{ scope.row.is_published ? "Published" : "Draft" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            allowAccessFor(['superadmin', 'admin', 'operations', 'kitchen'])
          "
          width="80"
        >
          <template slot-scope="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="eden-icon-edit"
                  :command="{
                    action: 'edit',
                    id: scope.row.id,
                    index: scope.$index,
                  }"
                  >Edit menu</el-dropdown-item
                >
                <el-dropdown-item
                  icon="el-icon-link"
                  :command="{
                    action: 'duplicate',
                    id: scope.row.id,
                    index: scope.$index,
                  }"
                  >Duplicate menu</el-dropdown-item
                >
                <el-dropdown-item
                  icon="eden-icon-eye"
                  :command="{
                    action: 'recommendations',
                    id: scope.row.id,
                    menu: scope.row,
                    index: scope.$index,
                  }"
                  >View recommendations</el-dropdown-item
                >
                <!-- <el-dropdown-item
                  v-if="allowAccessFor(['superadmin'])"
                  icon="el-icon-document"
                  :command="{
                    action: 'copy-recommendations',
                    id: scope.row.id,
                    index: scope.$index,
                  }"
                  >Generate recommendations</el-dropdown-item
                > -->
                <el-dropdown-item
                  icon="eden-icon-delete"
                  :command="{
                    action: 'delete',
                    id: scope.row.id,
                    index: scope.$index,
                  }"
                  >Delete menu</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <menu-recommendations-view
        :show.sync="showMenuRecommendationsView"
        :menu="selectedMenu"
      />
      <menu-recommendations-generate
        :show.sync="showMenuRecommendationsGenerate"
        :menu-id="menuId"
      />
      <eden-confirm-dialog
        :title="`${formatText(selectionWindowAction)} meal selection window`"
        :button-text="`Yes, ${selectionWindowAction}`"
        :button-type="selectionWindowAction === 'open' ? 'success' : 'danger'"
        :show.sync="showSelectionWindow"
        @confirm="changeSelectionWindow"
      >
        <p>
          Are you sure you want to {{ selectionWindowAction }} the meal
          selection window?
        </p>
      </eden-confirm-dialog>
      <eden-custom-calendar :show.sync="showCalendar" @setdate="setdate" />
      <eden-pagination
        v-if="showPagination"
        :from="from"
        :to="to"
        :total="total"
        :current-page.sync="page"
      />
    </div>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import menu from "@/requests/services/meal/menu";
import MenuRecommendationsView from "@/components/Services/Meal/Menu/MenuRecommendationsView";
import MenuRecommendationsGenerate from "@/components/Services/Meal/Menu/MenuRecommendationsGenerate";

export default {
  name: "Menus",
  components: {
    MenuRecommendationsView,
    MenuRecommendationsGenerate,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
      toggleSelectionWindow: false,
      showSelectionWindow: false,
      showCalendar: false,
      showMenuRecommendationsView: false,
      showMenuRecommendationsGenerate: false,
      dateSet: "All Time",
      menuType: {
        type: "all",
        text: "All Menus",
      },
      menuTypeOptions: [
        {
          type: "all",
          text: "All Menus",
        },
        {
          type: "published",
          text: "Published Only",
        },
        {
          type: "drafts",
          text: "Drafts Only",
        },
      ],
      selectedMenu: {},
      menuId: "",
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
    };
  },
  computed: {
    service() {
      return this.$route.params.service;
    },
    types() {
      return this.$store.getters.menus;
    },
    from() {
      return this.types.pages[this.page]?.from;
    },
    to() {
      return this.types.pages[this.page]?.to;
    },
    total() {
      return this.types.total;
    },
    title() {
      return `${this.loading ? 0 : this.total} Menus`;
    },
    selectionWindowAction() {
      if (!this.toggleSelectionWindow) {
        return "open";
      } else {
        return "close";
      }
    },
    menuTypes() {
      return this.$store.getters.menu_types;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.types.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getMenus();
      }
    },
  },
  created() {
    this.$store.dispatch(actions.GET_MENU_TYPES);

    const pageFetched = !!this.types.pages[this.page];
    if (pageFetched) {
      this.setPageData();
    } else {
      this.getMenus();
    }
  },
  methods: {
    getMenuType(menuTypeId) {
      return this.menuTypes?.find((item) => item.id === menuTypeId);
    },
    setdate(value) {
      console.log(value);
      this.dateSet = value?.text;
      const filterParams = {
        params: {
          period: value.value,
        },
        paramsLabel: {
          period: {
            key: "period",
            label: "Period",
            type: "list",
            options: [],
            value: [value.value],
          },
        },
      };
      this.filter(filterParams);
    },
    filter({ params, paramsLabel }) {
      console.log({ params, paramsLabel });
      const stringedParams = "&" + this.sortObjectAsParams(params);
      this.page = 1;
      this.filterParams = {
        status: true,
        params: { ...this.filterParams.params, ...params },
        paramsLabel: { ...this.filterParams.paramsLabel, ...paramsLabel },
      };
      this.getMenus(stringedParams);
    },
    addNewMenu() {
      this.$route.params.service = "menu";
      this.$router.push({ name: "services.service-type.add" });
    },
    viewMenu(id) {
      this.$route.params.service = "menu";
      this.$router.push({ name: "services.service-type.view", params: { id } });
    },
    editMenu(id) {
      this.$route.params.service = "menu";
      this.$router.push({
        name: "services.service-type.edit",
        params: { id, service: "menu" },
      });
    },
    changeSelectionWindow() {
      this.toggleSelectionWindow = !this.toggleSelectionWindow;
      this.showSelectionWindow = false;
    },
    menuChange(menuType) {
      this.menuType = menuType;

      const publishedFilterParams = {
        params: {
          is_published: 1,
        },
        paramsLabel: {
          is_published: {
            key: "is_published",
            label: "Published",
            type: "list",
            options: [],
            value: [1],
          },
        },
      };

      const draftFilterParams = {
        params: {
          is_published: 0,
        },
        paramsLabel: {
          is_published: {
            key: "is_published",
            label: "Published",
            type: "list",
            options: [],
            value: [0],
          },
        },
      };

      switch (menuType.type) {
        case "all":
          this.getMenus();
          break;
        case "published":
          this.filter(publishedFilterParams);
          break;
        case "drafts":
          this.filter(draftFilterParams);
          break;
        default:
          //   fetchall
          break;
      }
    },
    command(command) {
      this.menuId = parseInt(command.id);
      switch (command.action) {
        case "view":
          // this.menu(command.id, "menu");
          break;
        case "edit":
          this.editMenu(command.id);
          break;
        case "duplicate":
          this.duplicateMenu(command.id);
          break;
        case "recommendations":
          this.selectedMenu = command.menu;
          this.showMenuRecommendationsView = true;
          break;
        case "copy-recommendations":
          this.showMenuRecommendationsGenerate = true;
          break;
        case "delete":
          this.deleteMenu(command.id);
          break;
      }
    },
    getMenus(params = "") {
      this.loading = true;
      console.log(params);
      this.$store
        .dispatch(actions.GET_MENUS, { page: this.page, params })
        .then(() => {
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.types.pages[this.page].data;
      this.showPagination = true;
    },
    addNewItem() {
      this.$router.push({ name: "services.service-type.add" });
    },
    deleteMenu(id) {
      const itemIndex = this.pageData.findIndex((item) => item.id === id);
      const itemToDelete = this.pageData.find((item) => item.id === id);
      this.pageData.splice(itemIndex, 1);

      menu
        .delete({ menu_id: id })
        .then((response) => {
          this.$message.success(response.data.message);
        })
        .catch((error) => {
          this.pageData.splice(itemIndex, 0, itemToDelete);
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    duplicateMenu(id) {
      menu
        .duplicate(id)
        .then((response) => {
          this.$message.success(response.data.message);
          this.getMenus();
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-meal--image {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background: #798b83;
  margin-right: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 100%;
    background: #798b83;
  }

  + div {
    width: 70%;
  }
}
</style>
