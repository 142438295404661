<template>
  <el-dialog
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="35%"
    :loading="fetching"
  >
    <template slot="title">
      <p class="title">Generate Recommendations</p>
    </template>
    <div v-loading="fetching">
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex" class="mb-1">
          <el-col :span="24">
            <el-form-item label="Number of meals">
              <el-input
                type="text"
                v-model.number="form.number"
                v-only-number
                @input="validateNumberOfMeals"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <span v-if="showLimitMessage" class="limit-message fadeIn"
          >Number of meals cannot be more than 50</span
        >
      </el-form>
      <el-row v-if="!isEmptyMeals" class="fadeIn" type="flex">
        <el-col :span="24">
          <div class="allergies">
            <pre id="meals">{{ form.meals }}</pre>
          </div>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        :disabled="isNumberEmpty"
        plain
        @click="generateRecommendations"
        >Generate</el-button
      >
      <el-button
        :disabled="isEmptyMeals"
        type="primary"
        @click="copyRecommendations"
        >Copy</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import menu from "@/requests/services/meal/menu";

export default {
  name: "MenuRecommendationsGenerate",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    menuId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
      mealsInMenu: [],
      showLimitMessage: false,
      form: {
        number: 20,
        meals: [],
      },
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isNumberEmpty() {
      return !this.form.number;
    },
    isEmptyMeals() {
      return !this.form.meals.length;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.getMenu();
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.form.number = 20;
      this.form.meals = [];
    },
    getMenu() {
      this.fetching = true;
      menu
        .retrieve(this.menuId)
        .then((response) => {
          if (response.data.status) {
            this.mealsInMenu = [];
            /**
             ** Get only items with combos
             **/
            let mainItems = response.data.menu_items_full.filter(
              (item) => item.preset_combos_full.length,
            );

            /**
             ** Flatten items into combos
             **/
            mainItems.forEach((item) => {
              const combos = item.preset_combos_full.map((combo) => {
                return {
                  main: item.name,
                  preset_combo: `${item.name}, ${this.formatCombo(combo)}`,
                };
              });
              this.mealsInMenu = this.mealsInMenu.concat(...combos);
            });
            this.fetching = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.fetching = false;
        });
    },
    validateNumberOfMeals() {
      if (this.form.number >= 51) {
        this.form.number = 50;
        this.showLimitMessage = true;

        setTimeout(() => {
          this.showLimitMessage = false;
        }, 4000);
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    generateRecommendations() {
      const { number } = this.form;

      let recommendations = [];

      let indexes = [];
      let mealsLength = this.mealsInMenu.length;
      let limit = mealsLength < number ? mealsLength : number;

      while (indexes.length < limit) {
        const index = this.getRandomInt(this.mealsInMenu.length);
        if (indexes.indexOf(index) === -1) indexes.push(index);
      }

      indexes.forEach((index) => {
        recommendations.push(this.mealsInMenu[index]);
      });

      /**
       *  A recursive function to make sure the number of meals in recommendation is the number specified.
       */
      const remainder = () => {
        const count = number - recommendations.length;
        if (recommendations.length < number) {
          const combos = recommendations.slice(0, count);
          recommendations = recommendations.concat(...combos);
          remainder();
        }
      };

      if (recommendations.length < number) {
        remainder();
      }

      this.form.meals = recommendations;
    },
    copyRecommendations() {
      const meals = document.getElementById("meals");
      const content = document.createElement("textarea");
      content.textContent = meals.textContent;
      document.body.append(content);
      content.select();
      document.execCommand("copy");
      this.$message.success("Recommendations copied!");
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.875rem;
  color: #21312a;
  line-height: 1;
  font-weight: 600;
}

.title {
  color: #21312a;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 5px;
}

.allergies {
  height: 300px;
  overflow-y: scroll;

  pre {
    padding: 30px;
    background: #eeeeee;
    font-size: 15px;
    overflow-x: scroll;
  }
}

.limit-message {
  font-size: 13px;
  font-weight: 500;
  color: red;
  opacity: 0.7;
  display: block;
  position: relative;
  top: -10px;
}
</style>
