<template>
  <div class="menu-recommendation">
    <el-row type="flex" class="flex-wrap">
      <el-col :span="24">
        <div class="meal">
          <div class="title">
            <p>{{ main }}</p>
            <span> {{ sides }}</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "MenuRecommendation",
  props: {
    meal: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    main() {
      return this.formatMealName(this.meal.main_item);
    },
    sides() {
      if (this.meal.preset_combo) {
        const sides = this.meal.preset_combo.split(",");
        sides.shift();
        return sides.join(", ");
      }

      const { main_side, protein_side, other_side, sauce_side } = this.meal;
      return this.concatenateItems(
        main_side,
        protein_side,
        other_side,
        sauce_side,
      );
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.menu-recommendation {
  border-radius: 8px;
  min-height: 40px;
  width: 100%;
  transition: min-height 0.25s ease-in;

  .meal {
    background: rgba(33, 49, 42, 0.04);
    border: 1px solid rgba(33, 49, 42, 0.08);
    border-radius: 8px;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    min-height: 60px;

    .title {
      p {
        color: #21312a;
        margin-bottom: 1px;
      }

      span {
        color: #57645e;
        font-size: 13px;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      span {
        padding: 5px 7px;
        cursor: pointer;

        i {
          color: #798b83;
        }
      }
    }
  }
}
</style>
