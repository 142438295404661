import axios from "axios";

export default {
  get(menuId) {
    return axios.get(`rotational_menu/meal_recommendations/${menuId}`);
  },

  add(payload) {
    return axios.post(`rotational_menu/meal_recommendation`, payload);
  },

  update(recommendationId, payload) {
    return axios.put(
      `rotational_menu/meal_recommendation/${recommendationId}`,
      payload,
    );
  },

  delete(recommendationId) {
    return axios.delete(
      `rotational_menu/meal_recommendation/${recommendationId}`,
    );
  },
};
