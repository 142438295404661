<template>
  <el-dialog :visible.sync="shouldShow" @close="close" width="32%">
    <template slot="title">
      <p class="title">{{ title }}</p>
    </template>
    <div v-loading="fetching" class="recommendations">
      <el-row type="flex" align="center">
        <el-col :sm="8" :md="12">
          <p>{{ recommendations.length }} Recommendations</p>
        </el-col>
        <el-col :sm="16" :md="12">
          <el-input v-model="query" type="text" placeholder="Search"
            ><i slot="prefix" class="el-input__icon eden-icon--search"
          /></el-input>
        </el-col>
      </el-row>

      <el-row type="flex" class="flex-wrap">
        <el-col :span="24">
          <menu-recommendation
            v-for="(recommendation, i) in setRecommendations"
            :key="i"
            :meal="recommendation"
          />
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import recommendations from "@/requests/services/meal/recommendations";
import MenuRecommendation from "@/components/Services/Meal/Menu/MenuRecommendation";

export default {
  name: "MenuRecommendationsView",
  components: { MenuRecommendation },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      fetching: false,
      recommendations: [],
      query: "",
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      const start = this.shortDate(this.menu.from_date);
      const end = this.shortDate(this.menu.to_date);
      return `Menu: ${start} - ${end}`;
    },
    setRecommendations() {
      const query = this.query?.toLowerCase();

      if (query) {
        return this.recommendations.filter((combo) =>
          combo?.preset_combo?.toLowerCase()?.includes(query),
        );
      } else {
        return this.recommendations;
      }
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.getRecommendations();
      }
    },
  },
  methods: {
    close() {
      this.shouldShow = false;
      this.recommendations = [];
    },
    getRecommendations() {
      this.fetching = true;
      recommendations
        .get(this.menu.id)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.recommendations = data.all;
            console.log(this.recommendations, data);
          }
          this.fetching = false;
        })
        .catch(() => {
          this.$message.error("Error getting Menu Recommendations");
          this.fetching = false;
        });
    },
    getComboName(combo) {
      const { main_item, main_side, protein_side, other_side, sauce_side } =
        combo;

      return this.concatenateItems(
        main_item,
        main_side,
        protein_side,
        other_side,
        sauce_side,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.875rem;
  color: #21312a;
  line-height: 1;
  font-weight: 600;
}

.title {
  color: #21312a;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 5px;
}

.recommendations {
  height: 300px;
  overflow-y: scroll;

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .el-input__inner {
    max-width: 100px;
  }
}
</style>
